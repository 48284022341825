import { Link } from "gatsby"
import React, { useEffect, Fragment } from "react"
import { scrollToElement } from "../../../../../utility/utils"
import CTA from "../../../general/cta"

const Savings = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (
    <Fragment>
      <div className="kuda-inner--heading kuda-disclaimer--section">
        <div className="kuda-section--inner general-style">
          <div className="kuda-section--100 inner-spotlight--heading pad-bottom text-center">
            <Link to="/en-ng/legal/" className="color-black mb-3 mt-4 back-page">
              <div className="align-self-center mr-2">
                <div className="f-14">
                  Legal
                </div>
              </div>
              <span className="mr-2">
                <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                    fill="#C7C7CC"
                  ></path>
                </svg>
              </span>
              <span className="align-self-center f-14 mr-2 text-left">Nigeria</span>
              <span className="mr-2">
                <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                    fill="#C7C7CC"
                  ></path>
                </svg>
              </span>
              <span className="align-self-center f-14 text-bold text-left">Terms & Conditions</span>
            </Link>
            <div className="legal-left">
              <h1 className="kuda-section--heading mb-0 text-lg-biz text-xlbold color-primary title-bottom--spacing no-margins">
                Terms & Conditions - Savings Product
              </h1>
            </div>
          </div>


          <div className="kuda-section--100 kuda-spacing--inner">
            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing mb-4">
              Background
            </h1>
            <p className="color-black">
              The following Terms and Conditions (“T & Cs”) apply to any person who applies for or uses a savings product (“Kuda Save”) issued by Kuda Microfinance Bank Limited (hereinafter referred to as “Kuda” or the “Bank”) on the Kuda application. In these T & Cs, “we” “us” or “our” refer to Kuda and its successors, agents and assigns while “you” or “your” refers to a Customer.
            </p>
            <p className="color-black">
              By clicking accept at the bottom of these T & Cs, you agree that you have read, understood and agree to be bound by the terms contained herein and any subsequent amendments we make to them. In addition, you also agree to comply with the applicable laws, rules and regulations, currently in force or as may be enacted that relate to savings and interest in Nigeria.
            </p>
            <p className="color-black">
              Please note that these T & Cs are in addition to the regular terms and conditions that regulate the usage of your Kuda account (hereinafter referred to as your “Kuda Spending Account”) as well as all other policies of the Bank.
            </p>
            <p className="color-black bottom--spacing">
              You will be considered to have given your consent to us for the processing of your personal data when you accept theseT&C’s as indicated below. If you do not agree with theseT&Cs, do not click on accept below.
            </p>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">

            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
              Kuda Save
            </h1>
            <div className="text-left kuda-disclaimer--wrap">
              <p className="color-black bottom--spacing">
                Kuda Save helps you save money with minimal effort to a dedicated account for saving (Kuda Save Account). To do this and depending on the option you select, we can either debit your Kuda Spending Account at set frequencies, chosen by you or you can make a one-time lump sum savings from your Kuda Spending Account. There are no third-party fees incurred by you when transferring from your Kuda Spending Account to your Kuda Save Account. Upon expiration of the tenor (that is, the entire period you choose to save the specific sum(s) for), the accumulated funds (that is, the amount you have saved plus accrued interest, where applicable) will be transferred to your Kuda Spending Account.
              </p>
              <p className="color-black mb-3">
                Available Options:
              </p>
              <p className="color-black bottom--spacing">
                <ol className="diclaimer-list number-list">
                  <li>
                    <span className="text-bold">As You Want </span>- save whenever you want and withdraw whenever you want (without any charge).  This is not interest-yielding.
                  </li>
                  <li>
                    <span className="text-bold">Spend & Save </span> - save a percentage of what you spend. This is debited from your Kuda Spending Account automatically once you apply the relevant settings. This is not interest-yielding and you can withdraw these funds at anytime (without any charge).
                  </li>
                  <li>
                    <span className="text-bold">Save Frequently  </span>- save daily, weekly or monthly toward a target amount for a set tenor and earn interest according to the rates displayed on the Kuda App in line with applicable market rates. If you withdraw more than 20% of your saved sum at any time before the tenor, you will forfeit your total interest earned.  </li>
                  <li>
                    <span className="text-bold">Fixed Deposit </span>- save a specific sum for a fixed duration and earn interest at applicable rates displayed on the Kuda App in line with applicable market rates. If you make any withdrawals at any time before the expiration of the tenor, you will forfeit your total interest earned.
                  </li>
                </ol>
              </p>

            </div>

            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
              WITHDRAWALS
            </h1>
            <div className="text-left kuda-disclaimer--wrap">
              <p className="color-black bottom--spacing">
                You may choose to withdraw your money saved from any Kuda Save Account, at any time you prefer. For interest-earning savings, your interest will be forfeited where you choose to withdraw your money before the expiration of the tenor, as stated above in 1c and 1d.
              </p>
            </div>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
              FINANCIAL ADVICE
            </h1>
            <div className="text-left kuda-disclaimer--wrap">
              <p className="color-black bottom--spacing">
                Kuda is a licensed microfinance bank and not a financial adviser, and the Kuda Save product was designed to encourage its Customers' savings habits, and earn interest where applicable. We do not make any representations, warranties, or guarantees of any kind that the Kuda Save product is appropriate for you and you acknowledge that you are using the product voluntarily. Before using Kuda Save, you may consider obtaining additional information and advice from a financial adviser.
              </p>

            </div>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
              INTEREST
            </h1>
            <div className="text-left kuda-disclaimer--wrap">
              <p className="color-black bottom--spacing">
                For Kuda Save products which are tenor-based i.e. Flexible Save and Fixed Save, you are entitled to earn interest, as prescribed by the applicable market rates and as displayed on the Kuda App at the time of saving. Interest is only paid upon completion of the tenor without withdrawals.
              </p>
              <p className="color-black bottom--spacing">
                Interest earned shall be displayed on your Kuda App and shall be updated daily, weekly or monthly (where applicable). You agree that if you do not comply with the applicable saving tenor, your interest earned shall be forfeited.</p>

            </div>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
              FEES
            </h1>
            <div className="text-left kuda-disclaimer--wrap">
              <p className="color-black bottom--spacing">
                There are no fees (transaction fees, third-party fees) charged to you for your use of Kuda Save. We, however, reserve the right to charge fees for the Services in the future, at our sole discretion, upon which we will provide you with prior written notice.
              </p>

            </div>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
              USE OF KUDA SAVE
            </h1>
            <div className="text-left kuda-disclaimer--wrap">
              <p className="color-black bottom--spacing">
                You agree that your use of Kuda Save shall be only for your personal use and not for commercial use, nor shall
                you authorise any other person to use Kuda Save on your behalf.
              </p>
              <p className="color-black bottom--spacing">
                You shall be solely responsible for protecting your account information, password and mobile device from
                unauthorised use of Kuda Save, and you shall be solely responsible for any transactions involving your Kuda
                Save account, whether authorised or unauthorised.
              </p>
              <p className="color-black bottom--spacing">
                We shall not be responsible to you for any loss arising from transactions involving your Kuda Save account,
                where your account information and password were provided to us, or where your account was compromised,
                without any negligence or fault of ours or our employees.

              </p>
              <p className="color-black bottom--spacing">
                You agree to notify us immediately by e-mail: help@kuda.com or by phone: 0700022555832 where you suspect
                or realise that your account information or mobile phone has been compromised or any unauthorised activity
                has occurred.


              </p>

            </div>
          </div>
          <div className="kuda-section--100 kuda-spacing--inner">
            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
              LIMITATION OF LIABILITY
            </h1>
            <div className="text-left kuda-disclaimer--wrap">
              <p className="color-black bottom--spacing">
                We do not warrant that the Kuda Save that we provide pursuant to this T & Cs will always be available. We
                reserve the right to withdraw or vary these services at any time. We will not be liable to You or anyone if we
                are unable to carry out our responsibilities under these T & Cs as a result of anything that we cannot reasonably
                control. These include but shall not be limited to: network downtime or failures, system downtimes or
                maintenance issues, delays or malfunctions or any other related event arising from an external interface; the
                actions or omissions of any telecommunications authority, processor, strike, industrial action/disputes, natural
                disasters, acts of God etc.
              </p>
              <p className="color-black bottom--spacing">
                Kuda disclaims any liability for any transaction related to or arising from fraud or such other illegality. Except
                when caused by the Bank’s proven misconduct or negligence, we shall not be liable or responsible for any loss,
                injury, or damage whether direct or indirect, special or consequential caused by or arising from the use of Kuda
                Save including any loss or financial fraud arising from loss, unauthorised initiation of Transactions. You shall
                indemnify and hold us harmless from and against any losses, damages, fines or related claims related to this.

              </p>
              <p className="color-black bottom--spacing">
                Until you notify us that your account has been compromised, you will be liable for all transactions carried out
                through it, before we acknowledge receipt of the notification.

              </p>

            </div>
          </div>
          <div className="kuda-section--100 kuda-spacing--inner">
            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
              GENERAL TERMS
            </h1>
            <div className="text-left kuda-disclaimer--wrap">
              <p className="color-black bottom--spacing">
                We may, at any time, modify, revise and make changes to the provisions of these T &Cs and we will notify you
                of such changes after which they will become effective. All updates will be detailed on our website and the
                Kuda App. You will be able to access the latest version of our terms at any given time. Without prejudice to the
                other provisions of these T and Cs and any of our other rights, if you do not wish to accept any amendments
                we make to these T and Cs in the future, you will be able to opt out of these T and Cs and deactivate your Kuda
                Save without any penalty. Non-acceptance of updates to these T and Cs also gives us a right to deactivate your
                Kuda Save.

              </p>
              <p className="color-black bottom--spacing">
                No delay in the enforcement of any clause contained in these T & Cs shall be deemed to be a waiver of the
                Bank’s rights under these Terms, nor shall the Bank be prevented from enforcing its rights under these T and Cs
                at a later date.
              </p>
              <p className="color-black bottom--spacing">
                Your rights under these T & Cs are personal to You and cannot be assigned or transferred to a third party.
              </p>

              <p className="color-black bottom--spacing">
                You hereby consent to the Bank’s collection, storage and processing of your personal data provided by You in
                connection with Kuda Save provided under these T &Cs.
              </p>
              <p className="color-black bottom--spacing">
                You authorise us to exercise a right of set-off of your Kuda Save account at any time we deem fit in respect of
                your Account or any other account you maintain with us to settle any of your outstanding payments to us.
              </p>

              <p className="color-black bottom--spacing">

                Please contact us if you need further explanation on anything related to the use of your card. You may contact us at
                <a
                  href="mailto:help@kuda.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="color-primary link-decoration cursor-pointer ml-1"
                >help@kuda.com</a> or speak to our customer care representatives on 0700022555832. We may notify you about any changes or information by: (a) Sending you in-App messages or emails,
                (b) advertising in the press, our website any other means of public notification; (c)including messages in your account statements  (d)any other means of communication that we deem appropriate.
              </p>
              <p className="color-black bottom--spacing">These Terms and Conditions shall be governed by the laws of the Federal Republic of Nigeria</p>

            </div>
          </div>

        </div>
      </div>
      <CTA />
    </Fragment>
  )
}

export default Savings
