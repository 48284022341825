import React from "react"
import Savings from "../../../components/body/pages/en-ng/legal/savings"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const TermsAndConditionSavingsPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/legal/savings/"}
      title="Kuda Save - Pockets - Spend+Save - Terms and Conditions"
      description="Read and accept the terms and conditions of using Kuda Save - saving features including Spend+Save and Pockets - As You Want, Frequently, Fixed Deposit."
    />
    <Savings />
  </Layout>
)

export default TermsAndConditionSavingsPage
